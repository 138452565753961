import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Volume2, Menu, VolumeX, ArrowUpRight, X } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { audioManager } from "../utils/audioContext";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAudioReady, setIsAudioReady] = useState(false);

  const navigate = useNavigate();

  const initializeAudio = async () => {
    try {
      await audioManager.initialize();
      setIsAudioReady(true);
      setIsSoundOn(true);
    } catch (error) {
      console.error("Failed to initialize audio:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      // Get scrollbar width
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      // Prevent body scroll and compensate for scrollbar removal
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      // Restore scroll position when menu is closed
      const scrollY = document.body.style.top;
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [isMenuOpen]);

  const toggleSound = async () => {
    if (!isAudioReady) {
      await initializeAudio();
    } else {
      const newSoundState = !isSoundOn;
      setIsSoundOn(newSoundState);
      audioManager.setVolume(!newSoundState);
    }
  };

  const handleInteraction = async (type, index) => {
    if (!isAudioReady) {
      await initializeAudio();
    }
    if (isSoundOn) {
      audioManager.playSound(type, index);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    handleInteraction("click", 7);
  };

  const navigationItems = ["Services", "Client Work", "ROI", "Blog", "Contact"];

  return (
    <>
      <motion.nav
        className={`fixed top-0 left-0 right-0 z-[40] transition-all duration-500 ${
          isScrolled
            ? "bg-[#111111]/80 backdrop-blur-xl border-b border-white/5"
            : "bg-[#111111]/40 backdrop-blur-lg"
        }`}
      >
        {/* Decorative elements */}
        <div className="absolute inset-0 bg-gradient-to-r from-primary-purple/5 via-transparent to-primary-green/5 opacity-50" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />

        <div className="container mx-auto px-4 relative">
          <div className="flex items-center justify-between h-16 sm:h-24">
            {/* Logo */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              onHoverStart={() => handleInteraction("hover", 0)}
              onClick={() => handleInteraction("click", 0)}
            >
              <Link to="/" className="relative z-10">
                <img
                  src="/HEAR ME OUT - Logo - White _ Purple.svg"
                  alt="Hear Me Out"
                  className="h-8 sm:h-12"
                  loading="eager"
                />
              </Link>
            </motion.div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-10 relative z-10">
              {/* Sound Toggle for Desktop */}
              <motion.button
                className="text-white/80 hover:text-white transition-colors"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={toggleSound}
                onHoverStart={() => handleInteraction("hover", 1)}
              >
                {isSoundOn ? <Volume2 size={20} /> : <VolumeX size={20} />}
              </motion.button>

              {navigationItems.map((item, index) => (
                <motion.div
                  key={item}
                  whileHover={{ scale: 1.1 }}
                  onHoverStart={() => handleInteraction("hover", index + 2)}
                  onClick={() => handleInteraction("click", index + 2)}
                >
                  <Link
                    to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
                    className="text-white/70 hover:text-white transition-colors text-sm font-medium tracking-wide"
                  >
                    {item}
                  </Link>
                </motion.div>
              ))}
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button
                  onClick={() => {
                    document
                      .getElementById("contact")
                      ?.scrollIntoView({ behavior: "smooth" });
                    handleInteraction("click", 7);
                  }}
                  onMouseEnter={() => handleInteraction("hover", 7)}
                  className="px-6 py-3 bg-primary-green/90 backdrop-blur-sm text-white rounded-full text-sm font-medium tracking-wide inline-flex items-center gap-2 hover:bg-primary-green transition-colors border border-primary-green/20"
                >
                  LET'S TALK
                  <ArrowUpRight className="w-4 h-4" />
                </button>
              </motion.div>
            </div>

            {/* Mobile Controls */}
            <div className="flex md:hidden items-center gap-4 relative z-10">
              <motion.button
                className="text-white/80 hover:text-white"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={toggleSound}
              >
                {isSoundOn ? <Volume2 size={20} /> : <VolumeX size={20} />}
              </motion.button>

              <motion.button
                className="text-white/80 hover:text-white"
                onClick={() => {
                  setIsMenuOpen(true);
                  handleInteraction("click", 7);
                }}
                whileTap={{ scale: 0.95 }}
              >
                <Menu size={24} />
              </motion.button>
            </div>
          </div>
        </div>
      </motion.nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <>
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-[#111111]/95 backdrop-blur-xl z-[101]"
              onClick={closeMenu}
            />

            {/* Menu Content - Updated positioning */}
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "100%" }}
              transition={{ type: "tween", duration: 0.3 }}
              className="fixed inset-y-0 inset-x-0 bg-[#111111]/95 backdrop-blur-xl z-[102]" // Changed positioning
            >
              <motion.button
                className="absolute top-5 right-4 p-2 text-white/80 hover:text-white"
                onClick={closeMenu}
                whileTap={{ scale: 0.95 }}
              >
                <X size={24} />
              </motion.button>

              <div className="flex flex-col items-center justify-center min-h-screen px-6 py-16">
                {navigationItems.map((item, index) => (
                  <motion.div
                    key={item}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.1 }}
                    onHoverStart={() => handleInteraction("hover", index + 2)}
                    onClick={() => {
                      handleInteraction("click", index + 2);
                      closeMenu();
                    }}
                  >
                    <Link
                      to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
                      className="text-white/70 hover:text-white text-xl transition-colors py-3 block"
                    >
                      {item}
                    </Link>
                  </motion.div>
                ))}

                <motion.button
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: navigationItems.length * 0.1 }}
                  className="px-6 py-3 mt-4 bg-primary-green text-white rounded-full text-lg font-medium inline-flex items-center gap-2 hover:bg-primary-green/90 transition-colors"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onHoverStart={() => handleInteraction("hover", 7)}
                  onClick={() => {
                    handleInteraction("click", 7);
                    closeMenu();
                    navigate("/contact#contact");
                  }}
                >
                  LET'S TALK
                  <ArrowUpRight className="w-5 h-5" />
                </motion.button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
